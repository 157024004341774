import './App.css';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

export const App = () => {
    return (
        <Router>
            <div className="App container text-center">
                <div className={"row align-items-center justify-content-center"}>
                    <div className={"col col-lg-10 col-xl-8"}>
                        <img src={"zombie-debt.png"} width={"100%"} alt={"Whats wrong with the rest of the kitten, ya creep?"}/>
                    </div>
                </div>
            </div>
        </Router>
    )
}
